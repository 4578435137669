.ProseMirror {
  min-height: 40vh;
  border: none !important;
  border-radius: 10px;
  outline: none;
  padding: 1em;
}

.ProseMirror p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.items {
  position: relative;
  overflow: hidden;
}

.item {
  display: block;
  width: 100%;
  background: transparent;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
}

.item.is-selected,
.item:hover {
  //color: white;
  background: 	#F0F0F0;
}

/* tip tap */
.editor, .text-to-edit {
  a {
    text-decoration: underline;
  }
  ul p, ol p {
    display: inline-block;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul, ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0.4rem;
    margin-bottom: 10px;

  }

  h1 {
    color: rgb(0, 49, 128);
    font-weight: 600;
    font-size: 28px;
  }

  h2 {
    margin-top: 1.5rem;
    font-weight: 800;
    font-size: 25px;
  }

  h3 {
    font-size: 22px;
    color: #0066de ;
    font-weight: 400;
  }

  h4 {
    font-size: 19px;
    color: #0066de ;
    font-weight: 300;
  }

  h6 {
    font-size: 18px;
    background: #0066de;
    color: white;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
  }
}

.editor-tippy .tippy-box {
  max-width: 500px !important;
}

.tiptap-inline-component {
  display: inline;
}