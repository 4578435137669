@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap");

@import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@import "main.content.scss";
@import "project/project.prices";
@import "project/project.spreadsheet";
@import "elements/cropper";
@import "elements/drag";
@import "elements/datepicker";
@import "elements/editor";

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.specification-import-wrapper h6 {
  background-color:#0066DE;color:white;border-radius: 5px;
  padding-left:6px ;padding-right:6px ;padding-top:2px;padding-bottom:2px;display:inline-block;margin-bottom:1rem;
}
.w-auto {
  width:auto !important;
}
.koko-modal {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
}

.koko-modal .modal-header {
  background-color: white !important;
  margin: 0;
  padding: 16px 24px;
}



.koko-modal .modal-body  {

  justify-content: end !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.koko-modal .modal-body button {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  border-radius: 9999px !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  padding-top: 0.5rem !important;
  margin-left: 0.5rem;
  padding-bottom: 0.5rem !important;
}


.koko-modal .modal-header div {

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;

  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.koko-modal .modal-header svg {
  color: rgba(0, 0, 0, 0.87) !important;
  width:auto !important;

}

.article-ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wizard-sidebar {
  min-width: 400px;
}

@media (min-width: 1280px) {
  .prices-view-first-column {
    max-width: 300px !important;
  }
}

.min-w-5 {
  min-width: 1.25rem;
}

.top-14 {
  top: 4rem;
}

.top-20 {
  top: 5rem;
}

.w-40 {
  width:10rem;
}
.top-12 {
  top:3rem;
}
.min-h-sidebar {
  min-height: 2.5rem;
}
.h-14{
  height: 3.5rem;
}

.keep-all {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:inline;
}
.border-b-3 {
border-bottom-width: 3px;
}


.min-w-sidebar {
min-width:300px;
}
.ReactQueryDevtools {
display: none !important;
}

.border-r-prices:after {
border: 1px solid #f5eceb;

position: absolute;
right: 0;
top: 0;
bottom: -100vh;
content: "";
z-index: 1;
}

.prices-table tr td:first-child, .prices-table tr th:first-child {
background: #f7f5fd;
position: sticky;
left: 0;
z-index: 10;

border-right: 2px solid #e2e8f0;
}


/* TODO: nieuwe stijl zorgt er anders ff voor dat buttons er heel raar uitzien */
button.add-button {
  width: auto !important;

  svg {
    display: none !important;
  }

  span {
    padding-left: 0 !important;
  }
}

.project-block {
  min-width: 300px;
  @media (min-width: 1280px) {
    max-width: 300px;
  }
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebar, .user-bar {
  min-width: 200px;
}


.w-10 {
  min-width: 2.5rem;
}
textarea.dont-resize {
  resize: none;
}

.border-opacity-0 {
  --tw-border-opacity: 0;
}

/* zou in tailwind moeten zitten maar is niet zo? (versie?) */

.opacity-20 {
  opacity: 0.2;
}
.opacity-80 {
  opacity: 0.8;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.modal-body {
  max-height: 80vh;
}

body {
  min-height: 100vh;
}

body .MuiButtonBase-root {
  color: #003180 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  padding: 0 !important;
  outline: none !important;
  margin-right: 2rem !important;
  min-width: 0% !important;
}

@media screen and (max-width: 768px) {
  body .MuiButtonBase-root {
    margin-right: 1rem !important;
  }
}

body .MuiButtonBase-root.Mui-selected {
  font-weight: 800 !important;
}

.MuiTabs-scroller {
  position: relative;
}

.MuiTabs-indicator {
  z-index: 10;
  border-color: #003180;
}

.MuiTabs-scroller:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-bottom: 2px solid #f2eef9;
}

.modal-wrapper {
  z-index: 1000 !important;
}

.modal-body form {
  width: 100%;
}

.modal-body form h1 {
  margin-top: 0 !important;
}

.view-switcher {
  top: -25px;
}

.edit-group {
  left: -30px;
}

.koko-modal header button[aria-label="close"] {
  display: none !important;
}

.z-100 {
  z-index: 100;
}

.Toastify__toast--error {
  display:none !important;
}
.ReactQueryDevtools {
  //display: none;
}

.project-info-file:hover .bg-holder {
  opacity: 100 !important;
}

.z-300 {
  z-index: 300;
}

.dummy-anchor {
  margin-top: -20rem;
  text-indent: -999rem;
  overflow: hidden;
  pointer-events: none;
}

.z-500 {
  z-index: 500;
}

.filename {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-editor {
  height: 200px !important;
}

.quill {
  border-radius: 5px;
}

/* TODO: aanpassen */

.mal-10 {
  margin-left: 50px;
}

.mal-20 {
  margin-left: 75px;
}

.mal-30 {
  margin-left: 100px;
}

.mal-40 {
  margin-left: 125px;
}

.mal-50 {
  margin-left: 150px;
}

.specification-article {
  .article-actions {
    display: none;
  }

  &:hover .article-actions {
    display: flex;
  }
}

.map-icon {
  left: -50px;
  top: 25px;
}

.MuiTab-root {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}

.title-article-3,
.title-article-4,
.title-article-5,
.title-article-6 {
  font-size: 1.4rem !important;
  margin-bottom: 10px !important;
}

.title-article-2 {
  margin-left: 15px;
}

.breadcrumb-item {
  display: flex;

  &:not(:first-child):after {
    content: ">";
    display: flex;
    padding: 0px 5px;
  }
}

.activity-block img.large {
  max-height: 300px;
  object-fit: cover;
}

.cover {
  width: 60px;
  height: 60px;
  object-fit: cover;
  min-width: 60px;
  min-height: 60px;
}

.avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: fill;
  overflow: hidden;
  display: block;
  border-radius: 100%;
}

.project-info-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  overflow: hidden;
  display: block;
  border-radius: 30px;
}

.project-info-file {
  width: 200px;
  height: 200px;
  border-radius: 30px;
}

.layout-block {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(100px);
  height: 4rem;
}

.drop-shadow {
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.bg-alternating-column {
  background-color: #f1edfb;
  z-index: 2;
  position: relative;
}

.autosaving {
  transform: translateX(0) !important;
  position: absolute !important;
  bottom: 80px !important;
  right: 0 !important;
  left: 0 !important;
  transform: translateY(0) !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 300;
  color: black !important;
  cursor: default !important;
  pointer-events: none;
  box-shadow: none !important;
  width: 200px;
  background: none !important;

  .Toastify__progress-bar {
    background: black !important;
  }

  button {
    opacity: 0;
  }
}

div[contenteditable="true"] {
  //font-family: sans-serif;
  //min-height: 75px;
  //border: 1px dashed #aaa;
  //padding: 5px;
  //margin-right: 20px;
}

.types-table {
  td {
    vertical-align: baseline;
  }
}

.hover\:bg-purple-700:hover {
  --bg-opacity: 1;
  background-color: #0058ce;
}

.first_sub:first-child {
  border-top: 2px solid #0066DE;
}


/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}